import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  Divider,
  Grid,
  Card,
  IconButton,
  CardContent,
  Link,
} from "@mui/material";
import { data, appointment } from "../../util/constants";
import OrderSignModal from "../modal/OrderSignModal";
import { ReactComponent as AlphaIcon } from "../../assets/alpha_insurance.svg";
import { ReactComponent as ApprovedStamp } from "../../assets/approved_stamp.svg";
import PriorAuthQuestionnaire from "./PriorAuthQuestionnaire";
import { useNavigate } from "react-router-dom";
import Snackbar from "../Snackbar/Snackbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import WarningIcon from "@mui/icons-material/Warning";

const PriorAuthResponseCard = (props) => {
  const {
    patient,
    provider,
    priorAuthRequired,
    crdResponse,
    orderLink,
    reqDocumentLink,
    handleClick,
    submitted,
  } = props;

  const navigate = useNavigate();
  const [open, setOpen] = useState({ open: false, content: {} });
  const [disableUpdate, setDisableUpdate] = useState({
    disabled: false,
    description: [],
  });
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "info",
  });

  const [selectedCard, setSelectedCard] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleCardClick = (card, index) => {
    setSelectedCard(card);
    setCurrentIndex(index);
  };

  const handleNext = () => {
    if (currentIndex < crdResponse.cards.length - 1) {
      const nextIndex = currentIndex + 1;
      setCurrentIndex(nextIndex);
      setSelectedCard(crdResponse.cards[nextIndex]);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      setCurrentIndex(prevIndex);
      setSelectedCard(crdResponse.cards[prevIndex]);
    }
  };

  useEffect(() => {
    if (crdResponse.cards.length > 0) {
      setSelectedCard(crdResponse.cards[0]);
    }
  }, [crdResponse.cards]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Paper
        sx={{
          padding: "1rem",
          borderRadius: ".5rem",
          display: "flex",
          flexDirection: "column",
          border: "1px solid white",
          justifyContent: "center",
          alignItems: "center",
          background: "none",
        }}
      >
        {priorAuthRequired && crdResponse.cards[0].indicator == "info" && (
          <Typography component="h5" variant="h5" sx={{ color: "white" }}>
            Your prior authorization is{" "}
            <span
              style={{
                color: "orangered",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Pending
            </span>{" "}
            !! refer below for Additional Requirements.
          </Typography>
        )}
        {!priorAuthRequired && crdResponse.cards[0].indicator == "storage" && (
          <Typography component="h5" variant="h5" sx={{ color: "white" }}>
            Your prior authorization is{" "}
            <span
              style={{
                color: "#2FB300",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Created
            </span>
          </Typography>
        )}
        {!priorAuthRequired && crdResponse.cards[0].indicator == "info" && (
          <Typography component="h5" variant="h5" sx={{ color: "white" }}>
            Your prior authorization is{" "}
            <span
              style={{
                color: "#2FB300",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Approved
            </span>{" "}
            !! no further documents are required.
          </Typography>
        )}
        {!priorAuthRequired && crdResponse.cards[0].indicator == "critical" && (
          <Typography component="h5" variant="h5" sx={{ color: "white" }}>
            Encountered{" "}
            <span
              style={{
                color: "orangered",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Internal Server
            </span>{" "}
            error.
          </Typography>
        )}
        {priorAuthRequired && crdResponse.cards[0].indicator == "critical" && (
          <Typography component="h5" variant="h5" sx={{ color: "white" }}>
            Encountered{" "}
            <span
              style={{
                color: "orangered",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Internal Server
            </span>{" "}
            error.
          </Typography>
        )}
      </Paper>

      <Grid>
        <Typography component="h3" variant="h6" sx={{ color: "white" }}>
          Order Confirmation
        </Typography>
        <Divider sx={{ my: ".5rem", borderColor: "darkgray" }} />
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Grid container spacing={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box>
              <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
                <ArrowBackIosIcon sx={{ color: "white" }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                overflowX: "hidden",
                position: "relative",
              }}
            >
              {crdResponse?.cards.map((card, cardIndex) => (
                <Card
                  key={cardIndex}
                  sx={{
                    height: "4rem",
                    flexShrink: 0,
                    width: "20rem",
                    margin: "1rem",
                    transform: `translateX(-${currentIndex * 20 + 1}rem)`,
                    transition: "transform 0.5s ease",
                    backgroundColor:
                      selectedCard === card ? "rgb(27 29 87)" : "#2A2B47",
                    border: "1px solid #ABD9D9",
                    borderRadius: "1rem",
                  }}
                  onClick={() => handleCardClick(card, cardIndex)}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "white",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {card?.summary}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
            <Box>
              <IconButton
                onClick={handleNext}
                disabled={currentIndex === crdResponse.cards.length - 1}
              >
                <ArrowForwardIosIcon sx={{ color: "white" }} />
              </IconButton>
            </Box>
          </Box>
          <Grid item xs={12}>
            <Paper
              sx={{
                padding: "1.5rem",
                display: "flex",
                flexDirection: "column",
                border: "2px solid black",
              }}
            >
              <Box sx={{ border: "2px solid black", padding: "1rem" }}>
                <Grid container>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        padding: "0.5rem 0rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <AlphaIcon style={{ width: "3rem", height: "2rem" }} />
                        <Typography
                          component="h5"
                          variant="h5"
                          sx={{ color: "black", textTransform: "uppercase" }}
                        >
                          Alpha Insurance
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item container xs={8}>
                    <Grid item xs={6}>
                      <Typography component="p">
                        <span style={{ fontWeight: "bold" }}>Member ID :</span>{" "}
                        {patient.memberId}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="p">
                        <span style={{ fontWeight: "bold" }}>
                          Provider ID :{" "}
                        </span>{" "}
                        {provider.providerId}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="p">
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          Member Name :{" "}
                        </span>{" "}
                        {patient.firstName} {patient.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="p">
                        <span style={{ fontWeight: "bold" }}>
                          Provider Name :
                        </span>{" "}
                        {provider.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {selectedCard && (
                  <Box
                    mt={2}
                    sx={{
                      border: "2px solid black",
                      padding: "1rem",
                    }}
                  >
                    <Grid
                      item
                      container
                      rowSpacing={1}
                      sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {selectedCard?.summary && (
                        <Grid item xs={12}>
                          <Box
                            gap={0.5}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {selectedCard.indicator === "warning" && (
                              <WarningIcon color="warning" />
                            )}
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {selectedCard?.summary}
                            </Typography>
                          </Box>
                          <Divider
                            sx={{ my: ".5rem", borderColor: "darkgray" }}
                          />
                        </Grid>
                      )}
                      {selectedCard?.detail && (
                        <Grid item xs={9}>
                          <Typography component="p" sx={{ fontWeight: "bold" }}>
                            Details
                          </Typography>
                          <Typography component="p">
                            {selectedCard?.detail}
                          </Typography>
                        </Grid>
                      )}
                      {selectedCard?.suggestions &&
                        selectedCard?.suggestions[0].actions && (
                          <Grid item xs={3}>
                            <Typography
                              component="p"
                              sx={{ fontWeight: "bold" }}
                            >
                              Created On
                            </Typography>
                            <Typography component="p">
                              {new Date(
                                selectedCard.suggestions[0].actions[0].resource
                                  .occurrenceDateTime ??
                                  selectedCard.suggestions[0].actions[0]
                                    .resource.created
                              ).toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })}
                            </Typography>
                          </Grid>
                        )}
                      {selectedCard?.source && (
                        <Grid item xs={12}>
                          <Typography component="p" sx={{ fontWeight: "bold" }}>
                            Source
                          </Typography>
                          <Typography component="p">
                            {selectedCard.source.label} ({" "}
                            {selectedCard.source.topic.display} )
                          </Typography>
                        </Grid>
                      )}
                      {selectedCard?.suggestions && (
                        <Grid item xs={12}>
                          <Typography component="p" sx={{ fontWeight: "bold" }}>
                            Suggestions
                          </Typography>
                          <ul>
                            {selectedCard.suggestions &&
                              selectedCard.suggestions.map(
                                (suggestion, suggestionIndex) => (
                                  <>
                                    {suggestion.actions.map(
                                      (action, actionIndex) => (
                                        <li key={actionIndex}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "flex-start",
                                            }}
                                          >
                                            <Button
                                              variant="text"
                                              disabled={
                                                disableUpdate.disabled &&
                                                disableUpdate.description.includes(
                                                  action.description
                                                )
                                              }
                                              onClick={() => {
                                                setOpen({
                                                  open: true,
                                                  content: action,
                                                });
                                              }}
                                            >
                                              <Typography component="p">
                                                {action.description}
                                              </Typography>
                                            </Button>
                                            {suggestion.isRecommended ===
                                              true && " (recommended)"}
                                          </Box>
                                        </li>
                                      )
                                    )}
                                  </>
                                )
                              )}
                          </ul>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {priorAuthRequired && (
        <Paper
          sx={{
            padding: "1rem",
            borderRadius: ".5rem",
            display: "flex",
            flexDirection: "column",
            border: "1px solid white",
            justifyContent: "center",
            alignItems: "flex-start",
            background: "none",
          }}
        >
          <Typography component="p" variant="subtitle1" sx={{ color: "white" }}>
            Additional Documentation Requirements (Click the below links to
            proceed further.)
          </Typography>
          <ul>
            <li style={{ color: "white" }}>
              <Link
                href={reqDocumentLink}
                underline="hover"
                sx={{ color: "white" }}
              >
                Documentation Requirements
              </Link>
            </li>
            {orderLink.map((link) => (
              <li style={{ color: "white" }}>
                <Link
                  href={link.url}
                  underline="hover"
                  sx={{ color: "white" }}
                  target="_blank"
                >
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        </Paper>
      )}
      <Box
        component="div"
        mt={"auto"}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          startIcon={<ChevronLeftIcon />}
          sx={{
            backgroundColor: "#49498A",
            borderRadius: 2,
            "&:hover": {
              backgroundColor: "#49498A",
            },
          }}
        >
          Back to PA status Dashboard
        </Button>
      </Box>

      <OrderSignModal
        open={open?.open}
        setOpen={setOpen}
        content={open?.content}
        setOpenSnackbar={setOpenSnackbar}
        setDisableUpdate={setDisableUpdate}
      />
      <Snackbar openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} />
    </Box>
  );
};

export default PriorAuthResponseCard;
